import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { useForm, Controller } from 'react-hook-form'
import { Button } from '../../design'
import { useApp } from '../../context/appContext'
import { PASSWORD_REG_EXP, REQUIRED_FIELD, WRONG_PASSWORD_FORMAT } from '../../utils/constants'

const ChangePassword = () => {
  const [errorMessage, setErrorMessage] = useState()
  const [isSuccess, setIsSuccess] = useState(false)

  const { changePassword } = useApp()
  const {
    control,
    register,
    handleSubmit,
    reset,
    trigger,
    watch,
    formState: { errors, isSubmitting, isSubmitted, isDirty },
  } = useForm()

  const onSubmit = async ({ oldPassword, newPassword }) => {
    setErrorMessage(null)
    try {
      await changePassword(oldPassword, newPassword)
      setIsSuccess(true)
    } catch (err) {
      let message = 'Ошибка выполнения запроса. Пожалуйста, повторите попытку позже'
      if (err.message === 'Incorrect username or password.') {
        message = 'Неверный пароль'
      }
      setErrorMessage(message)
    } finally {
      reset(null, { keepValues: true })
    }
  }

  const triggerPasswordConfirmationValidation = () => {
    if (isSubmitted) {
      trigger('newPasswordConfirmation')
    }
  }

  const successView = (
    <>
      <p className="body-medium">Пароль успешно обновлен</p>
      <Button onClick={() => navigate('/user/dashboard')}>Перейти к странице в Вашими курсами</Button>
    </>
  )

  const changePasswordForm = (
    <>
      <input
        placeholder="Текущий пароль"
        type="password"
        autoComplete="off"
        {...register('oldPassword', {
          required: true,
        })}
      />
      {errors.oldPassword && <span className="auth-form__validation-error">{REQUIRED_FIELD}</span>}
      <Controller
        defaultValue=""
        control={control}
        name="newPassword"
        render={({ field }) => (
          <input
            onChange={e => {
              field.onChange(e)
              triggerPasswordConfirmationValidation()
            }}
            placeholder="Новый пароль"
            type="password"
            autoComplete="off"
          />
        )}
        rules={{
          required: true,
          pattern: {
            value: PASSWORD_REG_EXP,
            message: WRONG_PASSWORD_FORMAT,
          },
        }}
      />
      {errors.newPassword && (
        <span className="auth-form__validation-error">{errors.newPassword.message || REQUIRED_FIELD}</span>
      )}
      <input
        placeholder="Подтверждение нового пароля"
        type="password"
        autoComplete="off"
        {...register('newPasswordConfirmation', {
          required: true,
          validate: value => value === watch('newPassword') || 'Пароль не совпадает',
        })}
      />
      {errors.newPasswordConfirmation && (
        <span className="auth-form__validation-error">{errors.newPasswordConfirmation.message || REQUIRED_FIELD}</span>
      )}
      <Button className="auth-form__submit-btn" htmlType="submit" disabled={isSubmitting} loading={isSubmitting}>
        Поменять пароль
      </Button>
    </>
  )

  return (
    <form className="auth-form" onSubmit={handleSubmit(onSubmit)}>
      {errorMessage && !isDirty && <p className="body-medium auth-form__error">{errorMessage}</p>}
      {isSuccess ? successView : changePasswordForm}
    </form>
  )
}

export default ChangePassword
