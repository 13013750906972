import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { useApp } from '../../context/appContext'
import { getCognitoErrorMessage } from '../../utils/getCognitoErrorMessage'
import { Button } from '../../design'
import { REQUIRED_FIELD } from '../../utils/constants'

const ConfirmRegistration = ({ loginData }) => {
  const [errorMessage, setErrorMessage] = useState()
  const [isResendingCode, setIsResendingCode] = useState(false)

  const { signIn, confirmSignUp, resendSignUp } = useApp()
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm()

  async function onSubmit(data) {
    try {
      await confirmSignUp(loginData.email, data.confirmationCode)
      await signIn(loginData.email, loginData.password)
      navigate('/user/dashboard')
    } catch (err) {
      console.error(err)
      const message = getCognitoErrorMessage(err.message)
      setErrorMessage(message)
    } finally {
      reset(null, { keepValues: true })
    }
  }

  async function onResendCode(e) {
    e.preventDefault()
    setIsResendingCode(true)

    try {
      await resendSignUp(loginData.email)
    } catch (err) {
      console.error(err)
      const message = getCognitoErrorMessage(err.message)
      setErrorMessage(message)
    } finally {
      setIsResendingCode(false)
      reset(null, { keepValues: true })
    }
  }

  return (
    <form className="auth-form" onSubmit={handleSubmit(onSubmit)}>
      {errorMessage && !isDirty && <p className="body-medium auth-form__error">{errorMessage}</p>}
      <input placeholder="Код подтверждения" autoComplete="off" {...register('confirmationCode', { required: true })} />
      {errors.confirmationCode && <span className="auth-form__validation-error">{REQUIRED_FIELD}</span>}
      <Button className="auth-form__submit-btn" htmlType="submit" loading={isSubmitting} disabled={isSubmitting}>
        Подтвердить
      </Button>
      <Button type="link" onClick={onResendCode} disabled={isSubmitting} loading={isSubmitting || isResendingCode}>
        Выслать код ещё раз
      </Button>
    </form>
  )
}

export default ConfirmRegistration
