export const getCognitoErrorMessage = errorMessage => {
  switch (errorMessage) {
    case 'Incorrect username or password.':
      return 'Неверный адрес электронной почты или пароль'
    case 'Password attempts exceeded':
    case 'Attempt limit exceeded, please try after some time.':
      return 'Превышено максимальное количество запросов. Попробуйте позже'
    case 'An account with the given email already exists.':
      return 'Пользователь с таким адресом электронной почты уже зарегистрирован'
    case 'Invalid verification code provided, please try again.':
      return 'Неверный код подтверждения. Пожалуйста, попробуйте еще раз.'
    default:
      return 'Ошибка авторизации'
  }
}
