import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import ConfirmRegistration from './ConfirmRegistration'
import { Button } from '../../design'
import { useApp } from '../../context/appContext'
import { getCognitoErrorMessage } from '../../utils/getCognitoErrorMessage'
import { EMAIL_REG_EXP, REQUIRED_FIELD, WRONG_EMAIL_FORMAT } from '../../utils/constants'

const Login = () => {
  const [errorMessage, setErrorMessage] = useState()
  const [loginData, setLoginData] = useState(null)

  const { signIn, resendSignUp } = useApp()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isDirty },
  } = useForm()

  const onSubmit = async ({ email, password }) => {
    setErrorMessage(null)
    try {
      await signIn(email, password)
      navigate('/user/dashboard')
    } catch (err) {
      console.error(err)
      if (err.code === 'UserNotConfirmedException') {
        try {
          await resendSignUp(email)
          setLoginData({ email, password })
        } catch (err) {
          console.error(err)
          const message = getCognitoErrorMessage(err.message)
          setErrorMessage(message)
        }
      } else {
        console.error(err)
        const message = getCognitoErrorMessage(err.message)
        setErrorMessage(message)
      }
    } finally {
      reset(null, { keepValues: true })
    }
  }

  const onResetPassword = () => {
    navigate('/reset-password')
  }

  if (loginData) {
    return <ConfirmRegistration loginData={loginData} />
  }

  return (
    <form className="auth-form" onSubmit={handleSubmit(onSubmit)}>
      {errorMessage && !isDirty && <p className="body-medium auth-form__error">{errorMessage}</p>}
      <input
        placeholder="Электронная почта"
        {...register('email', {
          required: true,
          pattern: {
            value: EMAIL_REG_EXP,
            message: WRONG_EMAIL_FORMAT,
          },
        })}
      />
      {errors.email && <span className="auth-form__validation-error">{errors.email.message || REQUIRED_FIELD}</span>}
      <input
        placeholder="Пароль"
        type="password"
        autoComplete="off"
        {...register('password', {
          required: true,
        })}
      />
      {errors.password && <span className="auth-form__validation-error">{REQUIRED_FIELD}</span>}
      <Button className="auth-form__submit-btn" htmlType="submit" disabled={isSubmitting} loading={isSubmitting}>
        Войти
      </Button>
      <Button type="link" disabled={isSubmitting} onClick={onResetPassword}>
        Не помню пароль
      </Button>
    </form>
  )
}

export default Login
