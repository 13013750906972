import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '../../design'
import { useApp } from '../../context/appContext'
import { getCognitoErrorMessage } from '../../utils/getCognitoErrorMessage'
import ConfirmResetPassword from './ConfirmResetPassword'
import { EMAIL_REG_EXP, REQUIRED_FIELD, WRONG_EMAIL_FORMAT } from '../../utils/constants'

const ResetPassword = () => {
  const { forgotPassword } = useApp()
  const [errorMessage, setErrorMessage] = useState()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isDirty },
  } = useForm()
  const [email, setEmail] = useState()

  const onSendResetClick = async ({ email }) => {
    setErrorMessage(null)
    try {
      await forgotPassword(email)
      setEmail(email)
    } catch (err) {
      console.error(err)
      const message = getCognitoErrorMessage(err.message)
      setErrorMessage(message)
    } finally {
      reset(null, { keepValues: true })
    }
  }

  if (email) {
    return <ConfirmResetPassword email={email} />
  }

  return (
    <form className="auth-form" onSubmit={handleSubmit(onSendResetClick)}>
      {errorMessage && !isDirty && <p className="body-medium auth-form__error">{errorMessage}</p>}
      <p>Введите свой адрес электронной почты и мы вышлем Вам код для сброса пароля</p>
      <input
        placeholder="Электронная почта"
        {...register('email', {
          required: true,
          pattern: {
            value: EMAIL_REG_EXP,
            message: WRONG_EMAIL_FORMAT,
          },
        })}
      />
      {errors.email && <span className="auth-form__validation-error">{errors.email.message || REQUIRED_FIELD}</span>}
      <Button className="auth-form__submit-btn" htmlType="submit" disabled={isSubmitting} loading={isSubmitting}>
        Отправить код
      </Button>
    </form>
  )
}

export default ResetPassword
