import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import ConfirmRegistration from './ConfirmRegistration'
import { Button } from '../../design'
import { useApp } from '../../context/appContext'
import { getCognitoErrorMessage } from '../../utils/getCognitoErrorMessage'
import {
  EMAIL_REG_EXP,
  PASSWORD_REG_EXP,
  REQUIRED_FIELD,
  WRONG_EMAIL_FORMAT,
  WRONG_PASSWORD_FORMAT,
} from '../../utils/constants'

const Register = () => {
  const [errorMessage, setErrorMessage] = useState()

  const { signUp } = useApp()
  const [loginData, setLoginData] = useState(null)

  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    trigger,
    formState: { errors, isSubmitted, isSubmitting, isDirty },
  } = useForm()

  const onSubmit = async data => {
    setErrorMessage(null)

    try {
      await signUp(data.email, data.password)
      setLoginData({ email: data.email, password: data.password })
    } catch (err) {
      console.error(err)
      const message = getCognitoErrorMessage(err.message)
      setErrorMessage(message)
    } finally {
      reset(null, { keepValues: true })
    }
  }

  const triggerPasswordConfirmationValidation = () => {
    if (isSubmitted) {
      trigger('passwordConfirmation')
    }
  }

  if (loginData) {
    return <ConfirmRegistration loginData={loginData} />
  }

  return (
    <form className="auth-form" onSubmit={handleSubmit(onSubmit)}>
      {errorMessage && !isDirty && <p className="body-medium auth-form__error">{errorMessage}</p>}
      <input
        placeholder="Электронная почта"
        {...register('email', {
          required: true,
          pattern: {
            value: EMAIL_REG_EXP,
            message: WRONG_EMAIL_FORMAT,
          },
        })}
      />
      {errors.email && <span className="auth-form__validation-error">{errors.email.message || REQUIRED_FIELD}</span>}
      <Controller
        defaultValue=""
        control={control}
        name="password"
        render={({ field }) => (
          <input
            onChange={e => {
              field.onChange(e)
              triggerPasswordConfirmationValidation()
            }}
            placeholder="Пароль"
            type="password"
            autoComplete="off"
          />
        )}
        rules={{
          required: true,
          pattern: {
            value: PASSWORD_REG_EXP,
            message: WRONG_PASSWORD_FORMAT,
          },
        }}
      />
      {errors.password && (
        <span className="auth-form__validation-error">{errors.password.message || REQUIRED_FIELD}</span>
      )}
      <input
        placeholder="Подтвердите пароль"
        type="password"
        autoComplete="off"
        {...register('passwordConfirmation', {
          required: true,
          validate: value => value === watch('password') || 'Пароль не совпадает',
        })}
      />
      {errors.passwordConfirmation && (
        <span className="auth-form__validation-error">{errors.passwordConfirmation.message || REQUIRED_FIELD}</span>
      )}
      <Button className="auth-form__submit-btn" htmlType="submit" loading={isSubmitting} disabled={isSubmitting}>
        Зарегистрироваться
      </Button>
    </form>
  )
}

export default Register
