import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { useForm, Controller } from 'react-hook-form'
import { Button } from '../../design'
import { useApp } from '../../context/appContext'
import { getCognitoErrorMessage } from '../../utils/getCognitoErrorMessage'
import { PASSWORD_REG_EXP, REQUIRED_FIELD, WRONG_PASSWORD_FORMAT } from '../../utils/constants'

const ConfirmResetPassword = ({ email }) => {
  const [errorMessage, setErrorMessage] = useState()
  const [isResetSuccess, setIsResetSuccess] = useState(false)
  const [newPassword, setNewPassword] = useState()
  const [isLoginLoading, setIsLoginLoading] = useState(false)

  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    trigger,
    formState: { errors, isSubmitted, isSubmitting, isDirty },
  } = useForm()

  const { forgotPasswordSubmit, signIn } = useApp()

  async function onResetPasswordSubmit({ password, resetCode }) {
    setErrorMessage(null)

    try {
      await forgotPasswordSubmit(email, resetCode, password)
      setNewPassword(password)
      setIsResetSuccess(true)
    } catch (err) {
      console.error(err)
      const message = getCognitoErrorMessage(err.message)
      setErrorMessage(message)
    } finally {
      reset(null, { keepValues: true })
    }
  }

  const triggerPasswordConfirmationValidation = () => {
    if (isSubmitted) {
      trigger('passwordConfirmation')
    }
  }

  const onLoginClick = async () => {
    setErrorMessage(null)
    setIsLoginLoading(true)
    try {
      await signIn(email, newPassword)
      navigate('/user/dashboard')
    } catch (err) {
      console.error(err)
      const message = getCognitoErrorMessage(err.message)
      setErrorMessage(message)
    } finally {
      setIsLoginLoading(true)
    }
  }

  const successView = (
    <>
      <p className="body-medium">Пароль успешно обновлен</p>
      <Button onClick={onLoginClick} loading={isLoginLoading} disabled={isLoginLoading}>
        Войти
      </Button>
    </>
  )

  const resetForm = (
    <>
      <Controller
        defaultValue=""
        control={control}
        name="password"
        render={({ field }) => (
          <input
            onChange={e => {
              field.onChange(e)
              triggerPasswordConfirmationValidation()
            }}
            placeholder="Новый пароль"
            type="password"
            autoComplete="off"
          />
        )}
        rules={{
          required: true,
          pattern: {
            value: PASSWORD_REG_EXP,
            message: WRONG_PASSWORD_FORMAT,
          },
        }}
      />
      {errors.password && (
        <span className="auth-form__validation-error">{errors.password.message || REQUIRED_FIELD}</span>
      )}
      <input
        placeholder="Подтверждение нового пароля"
        type="password"
        autoComplete="off"
        {...register('passwordConfirmation', {
          required: true,
          validate: value => value === watch('password') || 'Пароль не совпадает',
        })}
      />
      {errors.passwordConfirmation && (
        <span className="auth-form__validation-error">{errors.passwordConfirmation.message || REQUIRED_FIELD}</span>
      )}
      <input placeholder="Код подтверждения" autoComplete="off" {...register('resetCode', { required: true })} />
      {errors.resetCode && <span className="auth-form__validation-error">{REQUIRED_FIELD}</span>}
      <Button className="auth-form__submit-btn" htmlType="submit" loading={isSubmitting} disabled={isSubmitting}>
        Обновить пароль
      </Button>
    </>
  )

  return (
    <form className="auth-form" onSubmit={handleSubmit(onResetPasswordSubmit)}>
      {errorMessage && !isDirty && <p className="body-medium auth-form__error">{errorMessage}</p>}

      {isResetSuccess ? successView : resetForm}
    </form>
  )
}

export default ConfirmResetPassword
